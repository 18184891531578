<template>
  <v-container>
    <v-dialog v-model="dialog" max-width="800px">
      <v-card>
        <v-card-title>
          <span v-html="$t('history')" />
        </v-card-title>
        <v-card-text>
          <rowDetails :productionForm="form" :productionFormRow="row" :correction="false"></rowDetails>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="closeDetails()">
            <span v-html="$t('close')" />
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row class="row search spacer-md">
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-legal-entity ref="search-legal-entity" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-farm ref="search-farm" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-contact ref="search-contact" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-local-unit ref="search-local-unit" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-dairy ref="search-dairy" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-subsidy-invoice ref="search-subsidy-invoice" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-production-approval ref="search-production-approval" @search="search" />
      </v-col>
    </v-row>
    <v-btn color="primary" @click="search"><span v-html="$t('production_approval_search')" /></v-btn>
    <v-btn color="secondary" @click="reset"><span v-html="$t('production_approval_reset')" /></v-btn>
    <v-btn color="secondary" :loading="downloading" @click="download"><span v-html="$t('production_download')" /></v-btn>

    <v-data-table-server
      :headers="fields"
      :items="items"
      :fields="fields"
      :loading="loading"
      @update:itemsPerPage="routerPushSize"
      @update:page="routerPushPage"
      @update:sort-by="routerPushDTSort"
      :sort-by="toDTSort(query.sort)"
      :page="getDTPageFromRoute(query.page)"
      :items-per-page="query.size"
      :items-per-page-options="$itemsPerPageOptions"
      :items-length="totalElements"
      :multi-sort="true"
      density="compact"
    >
      <template v-slot:[`item.agisId`]="{ item }">
        <template v-if="$privileges.has({ path: '/farms', permission: 'write' })">
          <router-link
            :to="{
              name: 'masterdata_farms_edit',
              params: { id: item.farmId, tab: 'overview' }
            }"
            class="tableaction link"
          >
            {{ item.agisId }}
          </router-link>
        </template>
        <template v-else>
          {{ item.agisId }}
        </template>
      </template>
      <template v-slot:[`item.farmerName1`]="{ item }">
        <template v-if="$privileges.has({ path: '/legalEntities', permission: 'write' })">
          <router-link
            :to="{
              name: 'masterdata_legal_entities_edit',
              params: { id: item.legalEntityId, tab: 'overview' }
            }"
          >
            {{ item.farmerName1 ? item.farmerName1 : '-' }}
          </router-link>
        </template>
        <template v-else>
          {{ item.farmerName1 ? item.farmerName1 : '-' }}
        </template>
      </template>
      <template v-slot:[`item.dairyIdent`]="{ item }">
        <template v-if="$privileges.has({ path: '/dairies', permission: 'write' })">
          <router-link
            :to="{
              name: 'masterdata_dairies_edit',
              params: { id: item.dairyId, tab: 'overview' }
            }"
            class="tableaction link"
          >
            {{ item.dairyIdent ? item.dairyIdent : '-' }}
          </router-link>
        </template>
        <template v-else>
          {{ item.dairyIdent ? item.dairyIdent : '-' }}
        </template>
      </template>
      <template v-slot:[`item.validUntil`]="{ item }">
        {{ $moment(item.validUntil).format('MM.YYYY') }}
      </template>
      <template v-slot:[`item.modification`]="{ item }">
        {{ $moment(item.modification).format('DD.MM.YYYY') }}
      </template>
      <template v-slot:[`item.remark`]="{ item }">
        <v-textarea
          class="nomessage nowheat"
          rows="1"
          auto-grow
          v-model="item.remark"
          :loading="item.loading"
          type="text"
          row-height="0"
          @blur="patchRow(item, true)"
        ></v-textarea>
      </template>
      <template v-slot:[`item.subsidyApprovedYes`]="{ item }">
        <v-checkbox
          v-model="item.subsidyApprovedYes"
          @update:modelValue="setRowStatus(item, 'APPROVED')"
          :loading="item.loading"
          :disabled="item.status != 'VALIDATION'"
        ></v-checkbox>
      </template>
      <template v-slot:[`item.subsidyApprovedNo`]="{ item }">
        <v-checkbox
          v-model="item.subsidyApprovedNo"
          @update:modelValue="setRowStatus(item, 'NOT_APPROVED')"
          :disabled="item.status != 'VALIDATION'"
          :loading="item.loading"
        ></v-checkbox>
      </template>

      <template v-slot:[`item.quantityCalculated`]="{ item }">
        <template v-if="$privileges.has({ path: '/productionFormRows', permission: 'read' })">
          <router-link
            :to="{
              name: 'production_form_rows_read',
              query: productionFormRowsQuery(item)
            }"
            class="tableaction link"
          >
            {{ $formatNumber(item['quantityCalculated'], 1) }}
          </router-link>
        </template>
        <template v-else>
          {{ $formatNumber(item['quantityCalculated'], 1) }}
        </template>
      </template>

      <template v-slot:[`item.edit`]="{ item }">
        <div class="link" color="primary" @click="openDetails(item)">
          <v-icon dbmblueprimary>mdi-text-box-edit-outline</v-icon>
        </div>
      </template>
    </v-data-table-server>

    <v-row justify="end">
      <v-col sm="6" md="4">
        <v-table density="compact" class="custom-simple-table" :loading="loadingCount">
          <tbody>
            <tr>
              <td>
                <span v-html="$t('productionformrows_quantityApproved')" />
              </td>
              <td align="right">{{ $formatNumber(quantityApproved, 1) }}</td>
            </tr>
            <tr>
              <td>
                <span v-html="$t('productionformrows_countNotApproved')" />
              </td>
              <td align="right">{{ $formatNumber(quantityNotApproved, 1) }}</td>
            </tr>
            <tr>
              <td><span v-html="$t('productionformrows_quantityTotal')" /></td>
              <td align="right">{{ $formatNumber(quantityTotal, 1) }}</td>
            </tr>
            <tr>
              <td>
                {{ countRows }}
                <span v-html="$t('productionformrows_row_count')" />
              </td>
              <td></td>
            </tr>
          </tbody>
        </v-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { apiURL } from '@/main'
import { Term } from '@/services/term'
import _ from 'lodash'
import { fileCreator, showError } from '@/services/axios'
import searchLegalEntity from '@/components/searchCards/searchLegalEntity.vue'
import { useSearchLegalEntityStore } from '@/store/SearchLegalEntityStore'
import searchFarm from '@/components/searchCards/searchFarm.vue'
import { useSearchFarmStore } from '@/store/SearchFarmStore'
import searchContact from '@/components/searchCards/searchContact.vue'
import { useSearchContactStore } from '@/store/SearchContactStore'
import searchLocalUnit from '@/components/searchCards/searchLocalUnit.vue'
import { useSearchLocalUnitStore } from '@/store/SearchLocalUnitStore'
import searchDairy from '@/components/searchCards/searchDairy.vue'
import { useSearchDairyStore } from '@/store/SearchDairyStore'
import SearchSubsidyInvoice from '@/components/searchCards/searchSubsidyInvoice'
import SearchProductionApproval from '@/components/searchCards/searchProductionApproval'
import { useSearchProductionStore } from '@/store/SearchProductionStore'

import details from '../productionForms/details'
import { DTSHeader } from '@/services/BackendService'
import { format, startOfMonth, addDays, addYears } from 'date-fns'
//import { useSearchSubsidyInvoiceStore } from '@/store/SearchSubsidyInvoiceStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'home',
  data() {
    return {
      dialog: false,
      downloading: false,
      items: [],
      loading: false,
      loadingCount: false,
      initialized: false,

      fields: <DTSHeader[]>[
        {
          title: this.$t('productionformrows_subsidyyes'),
          key: 'subsidyApprovedYes',
          sortable: false,
          align: 'left',
          width: '2%'
        },
        {
          title: this.$t('productionformrows_subsidyno'),
          key: 'subsidyApprovedNo', // this is a hacky solution to represent the no-subsidy approved column
          sortable: false,
          width: '2%',
          align: 'left'
        },
        {
          key: 'edit',
          sortable: false,
          width: '2%'
        },
        {
          title: this.$t('productionformrows_agisid'),
          key: 'agisId',
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('productionformrows_farm_type'),
          key: 'farmType' + this.$getUpLangKey(),
          sortable: true
        },
        {
          title: this.$t('productionformrows_farmname'),
          key: 'farmerName1',
          sortable: true
        },
        {
          title: this.$t('productionformrows_farmerName2'),
          key: 'farmerName2',
          sortable: true
        },
        /*{
          title: this.$t('productionformrows_farmstreet'),
          key: 'farmStreet',
          sortable: true
        },*/
        {
          title: this.$t('productionformrows_farmzip'),
          key: 'farmerZip',
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('productionformrows_farmlocality'),
          key: 'farmerLocality',
          sortable: true
        },
        {
          title: this.$t('productionformrows_dairyName'),
          key: 'dairyName',
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('productionformrows_dairyident'),
          key: 'dairyIdent',
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('productionformrows_reportingperiod'),
          key: 'validUntil',
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('productionformrows_animaltype'),
          key: 'animalType' + this.$getUpLangKey(),
          sortable: true
        },
        {
          title: this.$t('productionformrows_version_no'),
          key: 'versionNo',
          align: 'left',
          sortable: true
        },
        {
          title: this.$t('productionformrows_quantity_kg'),
          key: 'quantityCalculated',
          sortable: true,
          align: 'end'
        },
        {
          title: this.$t('productionformrows_remark'),
          key: 'remark',
          sortable: false,
          align: 'center'
        },
        {
          title: this.$t('productionformrows_modification'),
          key: 'modification',
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('production_form_row_user'),
          key: 'modifierName',
          sortable: true,
          align: 'left'
        }
      ],
      quantityApproved: 0,
      quantityNotApproved: 0,
      quantityTotal: 0,
      countRows: 0,
      totalElements: 0,
      form: {},
      row: {},
      animalTypeId: 0
    }
  },
  computed: {
    term(): any {
      return [
        ...Term.buildTermItems(useSearchLegalEntityStore(), useSearchLegalEntityStore().items),
        ...Term.buildTermItems(useSearchFarmStore(), useSearchFarmStore().items),
        ...Term.buildTermItems(useSearchContactStore(), useSearchContactStore().items),
        ...Term.buildTermItems(useSearchLocalUnitStore(), useSearchLocalUnitStore().items),
        ...Term.buildTermItems(useSearchDairyStore(), useSearchDairyStore().items)
        //...Term.buildTermItems(useSearchSubsidyInvoiceStore(), useSearchSubsidyInvoiceStore().items)
      ]
    },
    params() {
      return {
        validFrom: useSearchProductionStore().validFrom,
        validUntil: useSearchProductionStore().validUntil,
        animalTypeId: useSearchProductionStore().animalTypeId,
        isCorrection: useSearchProductionStore().isCorrection,
        status: useSearchProductionStore().status,
        subsidyApproved: useSearchProductionStore().subsidyApproved
      }
    },
    query(): any {
      return {
        term: Term.stringify(this.term),
        ...this.params,
        ...this.getJavaPageOptions({ sort: ['dairyIdent,asc', 'agisId,asc', 'validUntil,asc', 'versionNo,asc'] })
      }
    }
  },
  components: {
    SearchProductionApproval,
    SearchSubsidyInvoice,
    searchLegalEntity,
    searchFarm,
    searchContact,
    searchLocalUnit,
    searchDairy,
    rowDetails: details
  },
  methods: {
    search() {
      this.$router
        .push({
          path: this.$route.path,
          query: this.query
        })
        .catch((e) => {
          // route duplicated
          this.load()
        })
    },
    async load() {
      try {
        this.loading = true
        const response = await this.axios.get(apiURL + '/productionFormRows/findSubsidyApproval', {
          params: this.query
        })
        const items = response.data.content
        for (let item of items) {
          this.addApprovedYesNo(item)
        }
        this.items = Array.isArray(items) ? items : []
        this.totalElements = this.checkPage(response.data.totalElements)
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
      try {
        this.loadingCount = true
        const count = await this.axios.get(apiURL + '/productionFormRows/findSubsidyApprovalCount', {
          params: {
            term: Term.stringify(this.term),
            ...this.params
            //..._.pick(this.$route.query, ['sort', 'size', 'page'])
          }
        })
        this.quantityApproved = count.data.quantityApproved
        this.quantityNotApproved = count.data.quantityNotApproved
        this.quantityTotal = count.data.quantityTotal
        this.countRows = count.data.countRows
      } catch (e) {
        showError(e)
      } finally {
        this.loadingCount = false
      }
    },
    addApprovedYesNo(item: any) {
      item.subsidyApprovedYes = item.subsidyApproved == 'APPROVED' ? true : false
      item.subsidyApprovedNo = item.subsidyApproved == 'NOT_APPROVED' ? true : false
      return item
    },
    reset() {
      this.$refs['search-legal-entity'].reset()
      this.$refs['search-farm'].reset()
      this.$refs['search-contact'].reset()
      this.$refs['search-local-unit'].reset()
      this.$refs['search-dairy'].reset()
      this.$refs['search-subsidy-invoice'].reset()
      this.$refs['search-production-approval'].reset()
    },
    async patchRow(item, messageFieldOnly) {
      console.log('patchRow', item)
      item.loading = true
      let itemToSend = {}
      if (messageFieldOnly) {
        itemToSend = _.pick(item, ['id', 'remark'])
      } else {
        itemToSend = _.pick(item, ['id', 'subsidyApproved'])
      }
      try {
        let response = await this.axios.patch(`/api/productionFormRows/${itemToSend.id}`, itemToSend)
        console.log('patchItem', response)
        item.status = response.data.status
        item = this.addApprovedYesNo(item)
      } catch (e) {
        if (e?.response?.data?.title === 'DBM_ERROR_090') {
          item.subsidyApprovedNo = false
          item.subsidyApprovedYes = false
          item.subsidyApproved = 'NONE'
          item.remark = ''
        }
        showError(e)
      } finally {
        item.loading = false
      }
    },
    setRowStatus(item: any, status: string) {
      item.subsidyApproved = status
      this.patchRow(item, false)
    },
    openDetails(item) {
      this.form = {
        id: item.productionFormId,
        dairyIdent: item.dairyIdent,
        dairyName: item.dairyName
      }
      this.row = item
      this.row.statusName = this.row.status
      //this.row.statusName = this.statuses.find((status) => status.name === row.status)[this.$getLangKey()]
      this.dialog = true
    },
    closeDetails() {
      this.dialog = false
    },
    async download() {
      try {
        this.downloading = true
        const response = await this.axios.get(apiURL + '/productionFormRows/findSubsidyApproval/download', {
          params: {
            ...this.getJavaPageOptions(),
            term: Term.stringify(this.term)
          },
          headers: {
            Accept: 'application/msexcel'
          },
          responseType: 'blob'
        })
        fileCreator(await response, 'mengenfreigaben.xlsx')
      } catch (e) {
        let responseObj = await e.response.data.text()
        showError({ response: { data: JSON.parse(responseObj) } })
      } finally {
        this.downloading = false
      }
    },
    productionFormRowsQuery(item) {
      const this_month_a_year_ago = format(addYears(startOfMonth(new Date()), -1), 'yyyy-MM-dd')
      const first_of_last_month = format(addDays(startOfMonth(new Date()), -1), 'yyyy-MM-dd')
      return {
        term: 'Farm_agisId:' + item.agisId,
        validFrom: this_month_a_year_ago,
        validUntil: first_of_last_month,
        size: 50
      }
    }
  },
  watch: {
    /* if someone changes the route by hand */
    '$route.query': {
      handler(newValue) {
        this.load()
      },
      deep: true
    }
  },
  mounted() {
    this.initialized = true
    if (this.$route.query.size) this.load()
  }
})
</script>
